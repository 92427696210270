// import store from "@/state/store";

import PageBase from '@/components/base/page-base.vue';
import Notes from '@/views/notes/notes.vue';
import Attachment from '@/views/notes/attachment.vue';

export default [
    {
        path: "/login",
        name: "login",
        component: () => import("@/views/account/login"),
        meta: {
            title: "Login",
            // beforeResolve(routeTo, routeFrom, next) {
            //     if (store.getters["auth/loggedIn"]) {
            //         next({name: "notes"});
            //     } else {
            //         next();
            //     }
            // },
        },
    },
    {
        path: "/esqueceu-senha",
        name: "forgot-password",
        component: () => import("@/views/account/forgot-password.vue"),
        meta: {
            title: "Recuperar Senha",
        },
    },
    {
        path: "/recuperar-senha/:token",
        name: "change-password",
        component: () => import("@/views/account/change-password.vue"),
        meta: {
            title: "Resetar Senha",
        },
    },
    {
        path: "/logout",
        name: "logout",
        component: () => import("@/views/account/logout"),
        meta: {
            title: "Logout",
        },
    },
    {
        path: "/",
        name: "dashboard",
        meta: {
            title: "Dashboard",
            authRequired: true,
        },
        component: () => import("@/views/dashboard/index.vue"),
    },
    {
        path: "/usuarios",
        name: "users",
        meta: {
            title: "Usuários",
            authRequired: true,
        },
        component: () => import("@/views/users/users"),
    },
    {
        path: "/meu-perfil",
        name: "my-profile",
        meta: {
            title: "Meu Perfil",
            authRequired: true,
        },
        component: () => import("@/views/users/my-profile.vue"),
    },
    {
        path: "/contatos",
        name: "contacts",
        meta: {
            title: "Contatos",
            authRequired: true,
        },
        component: () => import("@/views/contacts/contacts.vue"),
    },
    {
        path: '/notas',
        component: PageBase,
        children: [
            {
                path: "",
                name: "notes",
                meta: {
                    title: "Notas",
                    authRequired: true,
                },
                component: Notes,
            },
            {
                path: ":id",
                name: "attachment",
                meta: {
                    title: "Nota Anexo",
                    authRequired: true,
                },
                component: Attachment,
            },
        ]
    },
];
