/**
 *
 * @param session string com o nome da localStorage
 */
export function setSessions(session) {
    if (session === 'Users') {
        /**
         * @param url string com a url destino no backend
         * @param params objeto com os campos do filtro, devem ter a mesma chave dos dados vindos do backend
         */
        const users = {
            url: 'users',
            params: {
                page: 1,
                order_by: 'id',
                order: 'asc',
                limit: 25,
                email: '',
                name: '',
            }
        }
        localStorage.setItem('Users', JSON.stringify(users));
    } else if (session === 'Contacts') {
        const contacts = {
            url: 'contacts',
            params: {
                page: 1,
                order_by: 'id',
                order: 'asc',
                limit: 25,
                email: '',
                name: '',
            }
        }
        localStorage.setItem('Contacts', JSON.stringify(contacts));
    } else if (session === 'Notes') {
        const notes = {
            url: 'notes',
            params: {
                page: 1,
                order_by: 'id',
                order: 'asc',
                limit: 25,
                title: '',
                description: '',
            }
        }
        localStorage.setItem('Notes', JSON.stringify(notes));
    }
}
