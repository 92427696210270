<script>
import Layout from "@/components/layouts/main.vue";
export default {
    components: {
        Layout,
    },
}

</script>
<!--eslint-disable no-mixed-spaces-and-tabs-->
<template>
    <Layout>
        <router-view></router-view>
    </Layout>
</template>
