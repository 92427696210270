<script>
import router from "@/router";
import {SimpleBar} from "simplebar-vue3";
import {layoutComputed} from "@/state/helpers";

import NavBar from "@/components/layouts/nav-bar.vue";
import Menu from "@/components/layouts/menu.vue";
import Footer from "@/components/layouts/footer.vue";

localStorage.setItem('hoverd', false);

export default {
    components: {NavBar, Footer, SimpleBar, Menu},
    data() {
        return {
            isMenuCondensed: false,
            config: {
                handler: this.handleRightBarClick,
                middleware: this.middleware,
                events: ["click"],
            },
        };
    },
    computed: {
        ...layoutComputed,
        topbar: {
            get() {
                return this.$store ? this.$store.state.layout.topbar : {} || {};
            },
            set(topbar) {
                this.changeTopbar({
                    topbar: topbar,
                });
            },
        },
        sidebarColor: {
            get() {
                return localStorage.getItem('mode') ? localStorage.getItem('mode') : 'light';
            },
            set(sidebarColor) {
                return this.changeSidebarColor({
                    sidebarColor: sidebarColor,
                });
            },
        },
        sidebarImage: {
            get() {
                return this.$store ? this.$store.state.layout.sidebarImage : {} || {};
            },
            set(sidebarImage) {
                return this.changeSidebarImage({
                    sidebarImage: sidebarImage,
                });
            },
        },
        mode: {
            get() {
                return localStorage.getItem('mode') ? localStorage.getItem('mode') : 'light';
            },
            set(mode) {
                // document.getElementById('app').classList.add(mode);
                return this.changeMode({
                    mode: mode,
                });
            },
        },
        visibility: {
            get() {
                return this.$store ? this.$store.state.layout.visibility : {} || {};
            },
            set(visibility) {
                if (visibility == 'hidden') {
                    document.querySelector(".hamburger-icon").classList.add("open");
                } else {
                    document.querySelector(".hamburger-icon").classList.remove("open");
                }
                this.changeVisibility({
                    visibility: visibility,
                });
            },
        },
    },
    created: () => {
        document.body.removeAttribute("data-layout", "horizontal");
        document.body.removeAttribute("data-topbar", "dark");
        document.body.removeAttribute("data-layout-size", "boxed");
    },
    methods: {
        hide() {
            this.$parent.toggleRightSidebar();
        },
        handleRightBarClick() {
            this.$parent.hideRightSidebar();
        },
        middleware(event) {
            if (event.target.classList)
                return !event.target.classList.contains("toggle-right");
        },
        removeExtraFade() {
            let fade = document.getElementsByClassName('offcanvas-backdrop fade show');
            for (let i = 0; i < fade.length; i++) {
                while (fade.length > 1) {
                    fade[i].remove();
                }
            }
        },

        topFunction() {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },

        resizeWindow() {
            var windowSize = document.documentElement.clientWidth;

            if (windowSize >= 1025) {
                if (document.documentElement.getAttribute("data-layout") === "vertical") {
                    document.documentElement.setAttribute("data-sidebar-size", this.$store.state.layout.sidebarSize);
                }
                if (document.documentElement.getAttribute("data-layout") === "semibox") {
                    document.documentElement.setAttribute("data-sidebar-size", this.$store.state.layout.sidebarSize);
                }
                if (document.documentElement.getAttribute("data-sidebar-visibility") === "show" && document.querySelector(".hamburger-icon")) {
                    document.querySelector(".hamburger-icon").classList.remove("open");
                }
            } else if (windowSize < 1025 && windowSize > 767) {
                document.body.classList.remove("twocolumn-panel");
                if (document.documentElement.getAttribute("data-layout") === "vertical") {
                    document.documentElement.setAttribute("data-sidebar-size", "sm");
                }
                if (document.documentElement.getAttribute("data-layout") === "semibox") {
                    document.documentElement.setAttribute("data-sidebar-size", "sm");
                }
                if (document.querySelector(".hamburger-icon")) {
                    document.querySelector(".hamburger-icon").classList.add("open");
                }
            } else if (windowSize <= 767) {
                document.body.classList.remove("vertical-sidebar-enable");
                document.body.classList.add("twocolumn-panel");
                if (document.documentElement.getAttribute("data-layout") !== "horizontal") {
                    document.documentElement.setAttribute("data-sidebar-size", "lg");
                }
                if (document.querySelector(".hamburger-icon")) {
                    document.querySelector(".hamburger-icon").classList.add("open");
                }
            }
        },
        initActiveMenu() {
            if (document.documentElement.getAttribute('data-sidebar-size') === 'sm-hover') {
                localStorage.setItem('hoverd', true)
                document.documentElement.setAttribute('data-sidebar-size', 'sm-hover-active');
            } else if (document.documentElement.getAttribute('data-sidebar-size') === 'sm-hover-active') {
                localStorage.setItem('hoverd', false)
                document.documentElement.setAttribute('data-sidebar-size', 'sm-hover');
            } else {
                document.documentElement.setAttribute('data-sidebar-size', 'sm-hover');
            }
        },
        toggleMenu() {
            document.body.classList.toggle("sidebar-enable");

            if (window.screen.width >= 992) {
                // eslint-disable-next-line no-unused-vars
                router.afterEach((routeTo, routeFrom) => {
                    document.body.classList.remove("sidebar-enable");
                    document.body.classList.remove("vertical-collpsed");
                });
                document.body.classList.toggle("vertical-collpsed");
            } else {
                // eslint-disable-next-line no-unused-vars
                router.afterEach((routeTo, routeFrom) => {
                    document.body.classList.remove("sidebar-enable");
                });
                document.body.classList.remove("vertical-collpsed");
            }
            this.isMenuCondensed = !this.isMenuCondensed;
        },
        toggleRightSidebar() {
            document.body.classList.toggle("right-bar-enabled");
        },
        hideRightSidebar() {
            document.body.classList.remove("right-bar-enabled");
        },
    },
    mounted() {
        if (localStorage.getItem('hoverd') == 'true') {
            document.documentElement.setAttribute('data-sidebar-size', 'sm-hover-active');
        }
        document.getElementById('overlay').addEventListener('click', () => {
            document.body.classList.remove('vertical-sidebar-enable')
        })

        let backtoTop = document.getElementById("back-to-top");

        if (backtoTop) {
            backtoTop = document.getElementById("back-to-top");
            window.onscroll = function () {
                if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
                    backtoTop.style.display = "block";
                } else {
                    backtoTop.style.display = "none";
                }
            };
        }

        Array.from(document.querySelectorAll("[name='data-sidebar']")).forEach(function (elem) {
            if (document.querySelector("[data-bs-target='#collapseBgGradient']")) {
                if (document.querySelector("#collapseBgGradient .form-check input:checked")) {
                    document.querySelector("[data-bs-target='#collapseBgGradient']").classList.add("active");
                } else {
                    document.querySelector("[data-bs-target='#collapseBgGradient']").classList.remove("active");
                    document.getElementById('collapseBgGradient').classList.remove('show');
                }

                elem.addEventListener("change", function () {
                    if (document.querySelector("#collapseBgGradient .form-check input:checked")) {
                        document.querySelector("[data-bs-target='#collapseBgGradient']").classList.add("active");
                    } else {
                        document.getElementById('collapseBgGradient').classList.remove('show');
                        document.querySelector("[data-bs-target='#collapseBgGradient']").classList.remove("active");
                    }
                });
            }
        });

        window.addEventListener("resize", this.resizeWindow);
    },
    watch: {
        mode: {
            immediate: true,
            deep: true,
            handler(newVal, oldVal) {
                if (newVal !== oldVal) {
                    switch (newVal) {
                        case "dark":
                            document.getElementById('app').classList.add('dark')
                            document.documentElement.setAttribute("data-layout-mode", "dark");
                            break;
                        case "light":
                            document.getElementById('app').classList.add('light')
                            document.documentElement.setAttribute(
                                "data-layout-mode",
                                "light"
                            );
                            break;
                    }
                }
            },
        },
        sidebarColor: {
            immediate: true,
            deep: true,
            handler(newVal, oldVal) {
                if (newVal !== oldVal) {
                    switch (newVal) {
                        case "dark":
                            document.documentElement.setAttribute("data-sidebar", "dark");
                            break;
                        case "light":
                            document.documentElement.setAttribute("data-sidebar", "light");
                            break;
                        case "gradient":
                            document.documentElement.setAttribute("data-sidebar", "gradient");
                            break;
                        case "gradient-2":
                            document.documentElement.setAttribute("data-sidebar", "gradient-2");
                            break;
                        case "gradient-3":
                            document.documentElement.setAttribute("data-sidebar", "gradient-3");
                            break;
                        case "gradient-4":
                            document.documentElement.setAttribute("data-sidebar", "gradient-4");
                            break;
                    }
                }
            },
        },
        preloader: {
            immediate: true,
            deep: true,
            handler(newVal, oldVal) {
                if (newVal !== oldVal) {
                    localStorage.setItem('data-preloader', newVal);
                }
            },
        },
        // sidebarImage: {
        //     immediate: true,
        //     deep: true,
        //     handler(newVal, oldVal) {
        //         if (newVal !== oldVal) {
        //             switch (newVal) {
        //                 case "img-1":
        //                     document.documentElement.setAttribute("data-sidebar-image", "img-1");
        //                     break;
        //                 case "img-2":
        //                     document.documentElement.setAttribute("data-sidebar-image", "img-2");
        //                     break;
        //                 case "img-3":
        //                     document.documentElement.setAttribute("data-sidebar-image", "img-3");
        //                     break;
        //                 case "img-4":
        //                     document.documentElement.setAttribute("data-sidebar-image", "img-4");
        //                     break;
        //                 case "none":
        //                     document.documentElement.setAttribute("data-sidebar-image", "none");
        //                     break;
        //             }
        //         }
        //     },
        // },
        sidebarView: {
            immediate: true,
            deep: true,
            handler(newVal, oldVal) {
                if (newVal !== oldVal) {
                    switch (newVal) {
                        case "detached":
                            document.documentElement.setAttribute(
                                "data-layout-style",
                                "detached"
                            );
                            break;
                        case "default":
                            document.documentElement.setAttribute(
                                "data-layout-style",
                                "default"
                            );
                            break;
                    }
                }
            },
        },
        position: {
            immediate: true,
            deep: true,
            handler(newVal, oldVal) {
                if (newVal !== oldVal) {
                    switch (newVal) {
                        case "fixed":
                            document.documentElement.setAttribute(
                                "data-layout-position",
                                "fixed"
                            );
                            break;
                        case "scrollable":
                            document.documentElement.setAttribute(
                                "data-layout-position",
                                "scrollable"
                            );
                            break;
                    }
                }
            },
        },
        sidebarSize: {
            immediate: true,
            deep: true,
            handler(newVal, oldVal) {
                if (newVal !== oldVal) {
                    switch (newVal) {
                        case "lg":
                            document.documentElement.setAttribute("data-sidebar-size", "lg");
                            break;
                        case "sm":
                            document.documentElement.setAttribute("data-sidebar-size", "sm");
                            break;
                        case "md":
                            document.documentElement.setAttribute("data-sidebar-size", "md");
                            break;
                        case "sm-hover":
                            document.documentElement.setAttribute(
                                "data-sidebar-size",
                                "sm-hover"
                            );
                            break;
                    }
                }
            },
        },
        topbar: {
            immediate: true,
            deep: true,
            handler(newVal, oldVal) {
                if (newVal !== oldVal) {
                    switch (newVal) {
                        case "light":
                            document.documentElement.setAttribute("data-topbar", "light");
                            break;
                        case "dark":
                            document.documentElement.setAttribute("data-topbar", "dark");
                            break;
                    }
                }
            },
        },
        layoutWidth: {
            immediate: true,
            deep: true,
            handler(newVal, oldVal) {
                if (newVal !== oldVal) {
                    switch (newVal) {
                        case "fluid":
                            document.documentElement.setAttribute(
                                "data-layout-width",
                                "fluid"
                            );
                            break;
                        case "boxed":
                            document.documentElement.setAttribute(
                                "data-layout-width",
                                "boxed"
                            );
                            break;
                    }
                }
            },
        },
        layoutType: {
            immediate: true,
            deep: true,
            handler(newVal, oldVal) {
                if (newVal !== oldVal) {
                    switch (newVal) {
                        case "horizontal":
                            document.documentElement.setAttribute(
                                "data-layout",
                                "horizontal"
                            );
                            break;
                        case "vertical":
                            document.documentElement.setAttribute("data-layout", "vertical");
                            break;
                        case "twocolumn":
                            document.documentElement.setAttribute("data-layout", "twocolumn");
                            break;
                        case "semibox":
                            document.documentElement.setAttribute("data-layout", "semibox");
                            break;
                    }
                }
            },
        },
        visibility: {
            immediate: true,
            deep: true,
            handler(newVal, oldVal) {
                if (newVal !== oldVal) {
                    switch (newVal) {
                        case "show":
                            document.documentElement.setAttribute(
                                "data-sidebar-visibility",
                                "show"
                            );
                            break;
                        case "hidden":
                            document.documentElement.setAttribute(
                                "data-sidebar-visibility",
                                "hidden"
                            );
                            break;
                    }
                }
            },
        },
    },
};
</script>

<template>
    <div id="layout-wrapper">
        <NavBar/>
        <div>
            <!-- ========== Left Sidebar Start ========== -->
            <!-- ========== App Menu ========== -->
            <div class="app-menu navbar-menu">
                <!-- LOGO -->
                <div class="navbar-brand-box">
                    <!-- Dark Logo-->
                    <router-link to="/" class="logo logo-dark">
                          <span class="logo-sm">
                            <img src="../../assets/images/logo-sm.png" alt="" height="35"/>
                          </span>
                        <span class="logo-lg">
                            <img src="../../assets/images/logo-text.png" alt="" height="35"/>
                        </span>
                    </router-link>
                    <!-- Light Logo-->
                    <router-link to="/" class="logo logo-light">
                          <span class="logo-sm">
                                <img src="../../assets/images/logo-sm.png" alt="" height="35"/>
                          </span>
                        <span class="logo-lg">
                            <img src="../../assets/images/logo-text-dark.png" alt="" height="35"/>
                        </span>
                    </router-link>
                    <button type="button" class="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
                            id="vertical-hover" @click="initActiveMenu">
                        <i class="ri-record-circle-line"></i>
                    </button>
                </div>

                <SimpleBar id="scrollbar" class="h-100" ref="scrollbar">
                    <Menu></Menu>
                </SimpleBar>
                <div class="sidebar-background"></div>
            </div>
            <!-- Left Sidebar End -->
            <!-- Vertical Overlay-->
            <div class="vertical-overlay" id="overlay"></div>
        </div>
        <!-- ============================================================== -->
        <!-- Start Page Content here -->
        <!-- ============================================================== -->

        <div class="main-content">
            <div class="page-content">
                <!-- Start Content-->
                <b-container fluid>
                    <slot/>
                </b-container>
            </div>
            <Footer/>
        </div>
        <div>

            <b-button variant="danger" @click="topFunction" class="btn-icon" id="back-to-top">
                <i class="ri-arrow-up-line"></i>
            </b-button>
        </div>
    </div>
</template>
