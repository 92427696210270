<template>
    <footer class="footer">
        <b-container fluid>
            <b-row>
                <b-col col sm="6">
                    {{ new Date().getFullYear() }} © InnovareTI.
                </b-col>
            </b-row>
        </b-container>
    </footer>
</template>
