<script>
import {
    endLoading, Forbidden,
    getModalValues,
    getUrl, loadTable, maskCpfCnpj, newOrder, notifySuccess,
    startLoading
} from "@/components/composables/functions";
import {apiComputed, apiMethods} from "@/state/helpers";
import {setSessions} from "@/components/composables/setSessions";
import Pagination from "@/components/base/pagination.vue";
import http from "@/http";
import Swal from "sweetalert2";
import store from "@/state/store";
import env from "@/env";

export default {
    components: {Pagination},
    props: [
        'session', 'table', 'changeList', 'button_modal', 'url'
    ],
    data() {
        return {
            loadTable: false,
            showModal: false,
            env
        };
    },

    methods: {
        newOrder,
        ...apiMethods,

        copyCnpj(value) {
            navigator.clipboard.writeText(maskCpfCnpj(value));
            notifySuccess('CNPJ copiado com sucesso!');
        },

        // Busca dos dados da tabela na store
        loadList() {
            if (!localStorage.getItem(this.session)) setSessions(this.session);
            const url = getUrl(this.session);
            this.getApi(url)
        },

        // Fazendo a busca no backend e setando os valores no modal, se tiver alguma condicional no
        // modal deve ser inserido aqui e depois desfeito no resetModal do componente Pai
        async getView(id) {
            store.commit('api/changeShowModalForm', true);
            startLoading('form', 'save');
            if (this.session === 'Users') {
                document.getElementById('password').required = false;
                document.getElementById('passwordDiv').style.display = 'none';
            }
            const storage = JSON.parse(localStorage.getItem(this.session));
            await http.get(`${storage.url}/${id}/?index=0&limit=5&order_by=id&order=asc`, {
                    headers: {'Authorization': ` Bearer ${localStorage.getItem('token')} `}
                })
                .then(response => {
                    getModalValues(response.data[0], 'form', 'save');
                })
                .catch(errors => {
                    console.error(errors);
                    endLoading('form', 'save');
                    Forbidden(errors);
                });
        },

        // Exclusão de item da tabela
        async confirm(id) {
            await Swal.fire({
                    title: "Você tem certeza?",
                    text: "Seus dados serão removidos e não poderão mais ser recuperados.",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    cancelButtonText: "Cancelar",
                    confirmButtonText: "Confirmar",
                })
                .then((result) => {
                    if (result.value) {
                        const data = {
                            id,
                            session: this.session
                        }
                        return store.dispatch('api/delete', data);
                    }
                })
                .catch(() => {
                    return false;
                });
        },

        getMaskCnpjButton (value) {
            let mask = maskCpfCnpj(value);
            return mask;
        },

        // Settar novo valor de limit na localStorage e chamando nova busca na store
        setLimit() {
            loadTable();
            let obj = JSON.parse(localStorage.getItem(this.session));
            obj.params.page = 1;
            obj.params.limit = document.getElementById('limitFilter').value;
            localStorage.setItem(this.session, JSON.stringify(obj));
            const data = {
                page: 1,
                limit: document.getElementById('limitFilter').value
            }
            store.commit('api/setPagination', data);
            this.loadList();
        },
    },

    mounted() {
        // chamada de busca de dados na store
        this.loadList();
    },

    computed: {
        ...apiComputed,
        start: {
            get() {
                return this.listAll.start || 0;
            }
        },
        partial: {
            get() {
                return this.listAll.partial || 0;
            }
        },
        total: {
            get() {
                return this.listAll.count || 0;
            }
        },
    }

};

</script>
<!--eslint-disable no-mixed-spaces-and-tabs-->
<template>
    <b-card-body>
        <div class="row align-items-center mt-4">
            <div class="col-auto">
                <span>Exibir </span>
            </div>
            <div class="col-auto">
                <select class="form-control form-select" id="limitFilter"
                        @change="setLimit">
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="250">250</option>
                </select>
            </div>
            <div class="col-auto">
                <span> registros</span>
            </div>
        </div>

        <div class="row">
            <div class="col mt-5">
                <p>Exibindo de <span id="start">{{ start }}</span> a <span id="partial">{{ partial }}</span> de
                    <span id="count">{{ total }}</span> registros</p>
            </div>
        </div>

        <div class="live-preview">
            <div class="table-responsive-xl mb-4">
                <table class="table table-striped align-middle mb-0" id="table" style="position: relative">
                    <thead>
                    <tr>

                        <!--                            Gerar as colunas do cabeçalho das tables-->
                        <th class="text-center" :class="thead.order ? 'relative' : ''"
                            v-for="(thead, index) in table" :key="'th' + index">
                            {{ thead.column }}
                            <i v-if="thead.order" class="ri-arrow-drop-up-fill pointer"
                               :id="thead.order + 'Asc'"
                               @click="newOrder(thead.order, session)"></i>
                            <i v-if="thead.order" class="ri-arrow-drop-down-fill pointer"
                               :id="thead.order + 'Desc'"
                               @click="newOrder(thead.order,  session)"></i>
                        </th>

                    </tr>
                    </thead>
                    <tbody>

                    <slot v-if="total > 0" name="table-body"></slot>

                    <tr v-else-if="Object.keys(listAll).length > 0">
                        <td :colspan="Object.keys(table).length" class="text-center">Nenhum resultado encontrado</td>
                    </tr>
                    </tbody>
                    <span class="spinner spinner-border flex-shrink-0" id="spinnerTable" role="status">
                        <span class="visually-hidden"></span>
                    </span>
                </table>
            </div>
            <Pagination ref="paginationComponent"
                        :total="total"
                        :session="session">
            </Pagination>
        </div>
    </b-card-body>
</template>

<style>

.icon-list {
    border: solid 1px #C6C6C6;
    border-radius: 5px;
}


</style>
