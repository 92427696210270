<script>

import {apiMethods} from "@/state/helpers";
import {setSessions} from "@/components/composables/setSessions";
import {getUrl, loadTable} from "@/components/composables/functions";
import store from "@/state/store";

export default {
    props: ['total', 'session'],
    // data() {
    //     if (!localStorage.getItem(this.session)) setSessions(this.session);
    //     const data = JSON.parse(localStorage.getItem(this.session));
    //     return {
    //         pagIndex: data.params.page,
    //         limit: data.params.limit,
    //     }
    // },
    methods: {
        ...apiMethods,

        loadList() {
            if (!localStorage.getItem(this.session)) setSessions(this.session)
            const url = getUrl(this.session);
            this.getApi(url)
        },
        async addValue() {
            if (this.pagIndex < this.totalPages) {
                loadTable();
                let obj = JSON.parse(localStorage.getItem(this.session));
                obj.params.page++;
                localStorage.setItem(this.session, JSON.stringify(obj));
                this.loadList()
                setTimeout(() => {
                    this.scrooll();
                }, 50)
                const data = {
                    page: this.pagIndex,
                    limit: this.limit
                }
                store.commit('api/setPagination', data)
            }
        },
        async subtractValue() {
            if (this.pagIndex > 1) {
                loadTable();
                const data = {
                    page: this.pagIndex,
                    limit: this.limit
                }
                store.commit('api/setPagination', data)
                let obj = JSON.parse(localStorage.getItem(this.session));
                obj.params.page--;
                localStorage.setItem(this.session, JSON.stringify(obj));
                this.loadList()
                setTimeout(() => {
                    this.scrooll();
                }, 50)
            }
        },
        async setValue(value) {
            if (value !== '...') {
                loadTable();
                let obj = JSON.parse(localStorage.getItem(this.session));
                obj.params.page = value ;
                localStorage.setItem(this.session, JSON.stringify(obj));
                this.loadList()
                setTimeout(() => {
                    this.scrooll();
                }, 50)
                const data = {
                    page: value ,
                    limit: this.limit
                }
                store.commit('api/setPagination', data)
            }
        },
        scrooll() {
            const elemento = document.getElementById('limitFilter');
            const altura = elemento.offsetHeight;
            window.scrollTo(0, altura + 50);
        },
        // resetValues(storage) {
        //     const data = JSON.parse(localStorage.getItem(storage));
        //     this.pagIndex = data.params.page;
        //     this.limit = data.params.limit;
        //     this.page = store.state.api.listAll.page + 1;
        // }
    },
    computed: {
        pagIndex() {
            return store.state.api.pagination.page + 1;
        },
        limit() {
            return store.state.api.pagination.limit;
        },
        totalPages() {
            return Math.ceil(this.total / this.limit);
        },
        pages() {
            const totalPages = Math.ceil(this.total / this.limit);

            if (totalPages <= 1) return [1];

            let arr = [];
            if (totalPages < 5) {
                for (let i = 1; i <= totalPages; i++) {
                    arr.push(i)
                }
            } else {
                if (this.pagIndex > 3) {
                    arr.push('...');
                }

                if (this.pagIndex < 3) {
                    for (let i = 1; i <= 5; i++) {
                        arr.push(i)
                    }
                } else if (this.pagIndex >= 3 && this.pagIndex < totalPages - 1) {
                    for (let i = this.pagIndex - 2; i <= this.pagIndex + 2; i++) {
                        arr.push(i)
                    }
                } else if (this.pagIndex >= totalPages - 1) {
                    for (let i = totalPages - 4; i <= totalPages; i++) {
                        arr.push(i)
                    }
                }


                if (this.pagIndex < totalPages - 2) {
                    arr.push('...');
                }
            }

            return arr;
        }
    }
}

</script>
<!--eslint-disable no-mixed-spaces-and-tabs-->
<template>
    <ul class="pagination justify-content-end pagination-primary"
        id="pagination" style="top: auto">

        <li class="page-item" :class="this.pagIndex === 1 ? 'disabled' : 'pointer'" @click="subtractValue">
            <a class="page-link">Anterior</a>
        </li>
        <li v-for="page in this.pages"
            :class="page === this.pagIndex ? 'active' : ''"
            :key="page"
            @click="setValue(page)"
            class="page-item pointer">
            <a class="page-link">{{ page }}</a>
        </li>
        <li class="page-item"
            :class="this.pagIndex === this.totalPages || (this.pagIndex == 1 && this.totalPages == 0 ) ? 'disabled' : 'pointer'"
            @click="addValue">
            <a class="page-link">Próximo</a>
        </li>
    </ul>


</template>
