<script>
import PageHeader from "@/components/layouts/page-header.vue";
import { onMounted, ref} from "vue";
// import store from "@/state/store";
import http from '@/http'
import {useRoute} from "vue-router";

export default {
    page: {
        title: "Nota Anexo",
    },
    components: {
        PageHeader,
        // Spinner
    },

    setup() {
        const attachment = ref(null)

        const getContact = () => {
            const route = useRoute();
            http.get('notes/attachment/' + route.params.id, {
                    headers: {'Authorization': ` Bearer ${localStorage.getItem('token')} `}
                })
                .then((response) => {
                    attachment.value = response.data;
                })
                .catch((e) => {
                    console.error(e);
                })
        }

        onMounted(() => {
            getContact();
        })

        return {
            title: "Notas",
            items: [
                {
                    text: "Notas",
                    href: "/notas",
                },
                {
                    text: "Anexo",
                    active: true,
                },
            ],
            attachment
        }
    }
}

</script>
<!--eslint-disable no-mixed-spaces-and-tabs-->
<template>
        <PageHeader :title="title" :items="items"/>
        <b-card>
            <b-card-header>
                <h5>Nota Anexo</h5>
            </b-card-header>
            <b-card-body>
                <iframe v-if="attachment"
                    width="100%"
                    height="1000"
                    :src="
                'data:application/pdf;base64, ' + encodeURI(attachment.base64) + ''
              "
                ></iframe>
            </b-card-body>
        </b-card>
</template>
