<script>
import PageHeader from "@/components/layouts/page-header.vue";
import TableLists from '@/components/base/table-lists.vue';
import Filter from '@/components/base/filter.vue';
import {setSessions} from "@/components/composables/setSessions";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import '@ckeditor/ckeditor5-link/build/translations/pt-br';
import {computed, onMounted, ref, watch} from "vue";
import store from "@/state/store";
import http from '@/http';
import {
    endLoading,
    getUrl, notifyError, notifySuccess,
    startLoading,
    ValidateForm
} from "@/components/composables/functions";
import Swal from "sweetalert2";
import {MultiSelect} from "vue-search-select"
import "vue-search-select/dist/VueSearchSelect.css"

export default {
    page: {
        title: "Notas",
    },
    components: {
        PageHeader,
        TableLists,
        Filter,
        ckeditor: CKEditor.component,
        MultiSelect
    },

    setup() {
        if (!localStorage.getItem('Notes')) setSessions('Notes');
        const contacts = ref([]);
        const openedModal = ref(false);
        const loading = ref(true);
        const titleInput = ref('');
        const description = ref('');
        const searchText = ref('');
        const items = ref([]);
        const lastSelectItem = ref({});

        const editorConfig = {
            pasteFromPasteText: true,
            additionalFilters: 'plainText',
            toolbar: ['heading', '|', 'bold', 'italic', '|', 'undo', 'redo'],
        }

        const getContacts = () => {
            http.get('contacts?paginated=false', {
                    headers: {'Authorization': ` Bearer ${localStorage.getItem('token')} `}
                })
                .then((response) => {
                    response.data.data.forEach(element => {
                        contacts.value.push({
                            value: element.id,
                            text: element.name.toUpperCase()
                        })
                    })
                })
                .catch((e) => {
                    console.error(e);
                })
        }

        const resetModal = () => {
            store.commit('api/changeShowModalForm', false);
            description.value = '';
            titleInput.value = '';
            document.getElementById('form').reset();
            document.getElementById('id').value = '';
            document.getElementById('spinner').style.display = 'none';
            document.getElementById('document-span').style.display = 'block';
            document.getElementById('document').required = true;
            endLoading('form', 'save');
            document.getElementById('form').classList.remove('was-validated');
            items.value = [];
        }

        watch(() => store.state.api.showModalForm, (newValue) => {
            openedModal.value = newValue;
        });

        const submitForm = () => {
            startLoading('form', 'save');
            if (!ValidateForm('form')) {
                endLoading('form', 'save');
                return;
            }


            let arr = [];

            items.value.forEach(element => {
                arr.push(element.value);
            })

            if (arr.length === 0) {
                notifyError('Você precisa selecionar ao menos um Contato!');
                endLoading('form', 'save');
                return;
            }

            let doc = document.getElementById("document");
            const id = document.getElementById('id').value
            const formData = {
                id,
                title: titleInput.value,
                description: description.value,
                contacts: arr,
                documents: doc.files[0] ? doc.files[0] : ""
            }

            if (id > 0) formData._method = 'PUT';


            http.post('notes/' + id, formData, {
                    headers: {
                        'Authorization': ` Bearer ${localStorage.getItem('token')} `,
                        'Content-Type': 'multipart/form-data',
                    }
                })
                .then(() => {
                    const searchUrl = getUrl('Notes');
                    store.dispatch('api/getApi', searchUrl);
                    resetModal();
                })
                .finally(() => {
                    endLoading('form', 'save');
                })
        }

        const getView = (id) => {
            store.commit('api/changeShowModalForm', true);
            startLoading('form', 'save');
            http.get('notes?with[]=contacts&id=' + id, {
                    headers: {'Authorization': ` Bearer ${localStorage.getItem('token')} `}
                })
                .then((response) => {
                    document.getElementById('id').value = id;
                    document.getElementById('document-span').style.display = 'none';
                    document.getElementById('document').required = false;
                    const data = response.data.data[0];
                    titleInput.value = data.title;
                    description.value = data.description;
                    data.contacts.forEach(element => {
                        items.value.push({
                            value: element.id,
                            text: element.name.toUpperCase()
                        })
                    })
                })
                .catch((e) => {
                    console.error(e);
                })
                .finally(() => {
                    endLoading('form', 'save');
                })
        }

        const confirm = async (id) => {
            await Swal.fire({
                    title: "Você tem certeza?",
                    text: "Seus dados serão removidos e não poderão mais ser recuperados.",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    cancelButtonText: "Cancelar",
                    confirmButtonText: "Confirmar",
                })
                .then((result) => {
                    if (result.value) {
                        const data = {
                            id,
                            session: 'Notes'
                        }
                        return store.dispatch('api/delete', data);
                    }
                })
                .catch(() => {
                    return false;
                });
        }

        const onSelect = (addItems, addLastSelectItem) => {
            items.value = addItems;
            lastSelectItem.value = addLastSelectItem;
        }

        const reSubmitNote = (id) => {
            if (loading.value) {
                loading.value = false;
                startLoading('cardNotes');
                http.get('notes/resubmit/' + id, {
                        headers: {
                            'Authorization': ` Bearer ${localStorage.getItem('token')} `,
                        }
                    })
                    .then(() => {
                        notifySuccess('Notas reenviadas com sucesso!');
                    })
                    .catch((e) => {
                        console.error(e);
                        notifyError('Erro ao reenviar Notas!');
                    })
                    .finally(() => {
                        endLoading('cardNotes');
                        loading.value = true;
                    })
            }
        }

        onMounted(() => {
            getContacts();
        })

        return {
            title: "Notas",
            data: JSON.parse(localStorage.getItem('Notes')),
            listAll: computed(() => store.state.api.listAll),
            submitForm,
            resetModal,
            openedModal,
            titleInput,
            description,
            editor: ClassicEditor,
            editorConfig,
            getView,
            confirm,
            contacts,
            onSelect,
            reSubmitNote,
            filter: {
                inputs: [
                    {
                        name: 'title',
                        label: 'Título E-mail',
                        col: '3',
                        type: 'text'
                    },
                    {
                        name: 'description',
                        label: 'Descrição E-mail',
                        col: '3',
                        type: 'text'
                    },
                ],
                selects: []
            },
            table: [
                {
                    column: 'ID',
                    order: 'id'
                },
                {
                    column: 'Título E-mail',
                    order: 'name'
                },
                {
                    column: 'Anexo',
                },
                {
                    column: 'Ações',
                }
            ],
            searchText,
            items,
            lastSelectItem
        }
    }
}

</script>
<!--eslint-disable no-mixed-spaces-and-tabs-->
<template>
    <PageHeader :title="title"/>
    <b-card id="cardNotes">
        <Filter
            session="Notes"
            title="Nota"
            col="2"
            url="notes/"
            :button_modal="true"
            :filter="filter"
        />

        <TableLists
            session="Notes"
            url="notes/"
            :button_modal="false"
            :table="table"
        >

            <template v-slot:table-body>
                <tr v-for="line in listAll.data" :key="'tr' + line.id" :id="'line' + line.id">
                    <td class="text-center">{{ line.id }}</td>
                    <td class="text-center">{{ line.title }}</td>
                    <td class="text-center">
                        <router-link :to="'notas/' + line.id">
                            <i class="bx bxs-file-pdf text-danger fs-3"></i>
                        </router-link>
                    </td>
                    <td class="text-center">
                        <a @click="getView(line.id)" class="pointer" title="Editar Nota">
                            <i class="ri-pencil-line align-bottom text-info pointer mx-1"></i>
                        </a>
                        <a class=" pointer" @click="confirm(line.id)" title="Excluir Nota">
                            <i class="ri-delete-bin-2-line align-bottom text-danger mx-2"></i>
                        </a>
                        <a class=" pointer" @click="reSubmitNote(line.id)" title="Reenviar Nota">
                            <i class="ri-mail-send-line align-bottom text-success mx-1"></i>
                        </a>
                    </td>
                </tr>

            </template>

        </TableLists>


        <b-modal v-model="openedModal" centered size="md" title="Adicionar Histórico" class="v-modal-custom"
                 header-class="p-3 bg-soft-primary text-primary"
                 scrollable id="modalLogs" @hidden="resetModal">
            <form id="form" novalidate>
                <b-row class="g-3">
                    <input type="hidden" name="id" id="id">
                    <div class="mb-3">
                        <label for="title">Título E-mail <span class="text-danger">*</span></label>
                        <input v-model="titleInput" id="title" name="title" class="form-control" type="text"
                               placeholder="Nome" required
                               maxlength="200">
                    </div>
                    <b-col xl="12">
                        <label for="description">Descrição <span class="text-danger">*</span></label>
                        <div class="ckeditor-classic">
                            <ckeditor v-model="description" :editor="editor" :config="editorConfig"></ckeditor>
                        </div>
                    </b-col>
                    <div class="mb-3">
                        <label for="document">Arquivo <span class="text-danger" id="document-span">*</span></label>
                        <input id="document" name="document" class="form-control" type="file" required
                               accept=".pdf">
                    </div>
                    <div class="mb-3">
                        <multi-select
                            :options="contacts"
                            :selected-options="items"
                            placeholder="Selecione um ou mais Contatos"
                            @select="onSelect"
                            :hideSelectedOptions="false"
                        >
                        </multi-select>
                    </div>
                </b-row>
            </form>
            <template v-slot:footer>
                <button class="btn btn-soft-danger mx-2 my-1" type="button" id="closeModalRegister" @click="resetModal">
                    <i class="ri-close-circle-line align-bottom"></i> Cancelar
                </button>
                <button class="btn btn-soft-success my-1" type="submit" id="save" @click="submitForm">
                    <i class="ri-checkbox-circle-line align-bottom"></i> Salvar
                </button>
            </template>
        </b-modal>
    </b-card>
</template>
