<script>
import {
    getUrl,
    loadTable
} from "@/components/composables/functions";
import {apiMethods} from "@/state/helpers";
import {setSessions} from "@/components/composables/setSessions";
import store from "@/state/store";

export default {
    props: [
        'session', 'title', 'col', 'filter', 'button_modal', 'url'
    ],
    data() {
        return {
            loadTable: false,
        };
    },

    methods: {
        ...apiMethods,

        openModal() {
            store.commit('api/changeShowModalForm', true);
        },

        // Inserindo valores no filter de acordo com os valores que estão na localStorage
        getFilter() {
            const obj = JSON.parse(localStorage.getItem(this.session))
            const keys = Object.keys(obj.params);
            for (let i = 0; i < keys.length; i++) {
                const input = document.getElementById(keys[i] + 'Filter');
                if (input) input.value = obj.params[keys[i]];
            }
        },

        // Resetando os valores da localSortorage, do filter e fazendo nova busca na store
        resetTable() {
            loadTable()
            setSessions(this.session);
            document.getElementById('filter').reset();
            this.filterTable();
        },

        // Efetuando o filtro da tabela, setando os valores na localStorage e efetuando nova busca na store
        filterTable() {
            loadTable();
            // Pegando a localStorage
            let obj = JSON.parse(localStorage.getItem(this.session));
            // Pegando o array das chaves das variáveis da url
            const keys = Object.keys(obj.params);

            // Selecionando apenas os valores do formulário, os demais permanecem iguais
            for (let i = 0; i < keys.length; i++) {

                if (document.getElementById(keys[i] + 'Filter')) obj.params[keys[i]] = document.getElementById(keys[i] + 'Filter').value;

            }
            // Zerando o index
            obj.params.page = 1;
            // Salvando a localStorage
            localStorage.setItem(this.session, JSON.stringify(obj));
            // this.$refs.paginationComponent.resetValues(this.session);
            this.loadList();
        },

        loadList() {
            if (!localStorage.getItem(this.session)) setSessions(this.session);
            const url = getUrl(this.session);
            this.getApi(url)
        },
    },

    mounted() {
        // chamada para inserir dados da localStorage no filtro
        this.getFilter();
    },

};

</script>
<!--eslint-disable no-mixed-spaces-and-tabs-->
<template>
    <b-card-header>
        <form id="filter" @submit.prevent="filterTable">
            <div class="row d-flex">
                <b-col :md="this.col || '2'" v-if="title" class="d-flex my-1">
                    <a v-if="button_modal"
                              style="width: 150px;white-space: nowrap"
                              @click="openModal"
                              class="m-r-10 btn btn-soft-primary align-center">
                        <i class="ri-add-circle-line align-bottom"></i> {{ this.title }}
                    </a>
                    <router-link v-else class="btn btn-primary text-white m-r-10"
                                 style="width: 150px;white-space: nowrap" :to="'/' + this.url + 'cadastrar'"><b>
                        <i class="mdi mdi-plus"></i></b> {{ this.title }}
                    </router-link>
                </b-col>

                <b-col v-for="(input, index) in filter.inputs" :key="'input' + index" class="my-1" :md="input.col">
                    <input class="form-control" :placeholder="input.label" :name="input.name"
                           :id="input.name + 'Filter'">
                </b-col>
                <b-col v-for="(select, index) in filter.selects" :key="'select' + index" class="my-1"
                       :md="select.col">
                    <select class="form-control form-select" :name="select.name"
                            :id="select.name + 'Filter'">
                        <option v-for="(option, index) in select.options" :key="'option' + index"
                                :value="option.value">
                            {{ option.name }}
                        </option>
                    </select>
                </b-col>
                <slot name="form-filter"></slot>
                <div class="col text-end">
                    <a v-if="session === 'PhysicalAssessment'"
                       class="btn btn-soft-info my-1">
                        <i class="ri-file-ppt-2-line align-bottom"></i> PDF
                    </a>
                    <button v-if="Object.keys(filter.inputs).length > 0 || Object.keys(filter.selects).length > 0"
                            class="btn btn-soft-danger mx-2 my-1" type="reset" @click="resetTable">
                        <i class="ri-close-circle-line align-bottom"></i> Limpar
                    </button>
                    <button v-if="Object.keys(filter.inputs).length > 0 || Object.keys(filter.selects).length > 0"
                       class="btn btn-soft-primary my-1" @click="filterTable" type="submit">
                        <i class="ri-search-2-line align-bottom"></i> Buscar
                    </button>
                </div>
            </div>
        </form>
    </b-card-header>
</template>
