<script>
import { authComputed, authMethods } from "@/state/helpers";
import store from "@/state/store";

/**
 * Nav-bar Component
 */
export default {
    data() {
        return {
            text: null,
            flag: null,
            value: null,
            myVar: 1,
            windowSize: document.documentElement.clientWidth,
            store,
        };
    },
    methods: {
        ...authMethods,
        toggleHamburgerMenu() {
            var windowSize = document.documentElement.clientWidth;
            let layoutType = document.documentElement.getAttribute("data-layout");
            let visiblilityType = document.documentElement.getAttribute("data-sidebar-visibility");

            document.documentElement.setAttribute("data-sidebar-visibility", "show");

            //For collapse vertical menu
            if (visiblilityType === "show" && (layoutType === "vertical" || layoutType === "semibox")) {
                if (windowSize < 1025 && windowSize > 767) {
                    document.body.classList.remove("vertical-sidebar-enable");
                    if (document.documentElement.getAttribute("data-sidebar-size") == "sm") {
                        store.commit('layout/CHANGE_SIDEBAR_TYPE', '');
                        document.documentElement.setAttribute("data-sidebar-size", "");
                    } else {
                        document.documentElement.setAttribute("data-sidebar-size", "sm");
                        store.commit('layout/CHANGE_SIDEBAR_TYPE', 'sm');
                    }
                } else if (windowSize > 1025) {
                    document.body.classList.remove("vertical-sidebar-enable");
                    if (document.documentElement.getAttribute("data-sidebar-size") == "lg") {
                        store.commit('layout/CHANGE_SIDEBAR_TYPE', 'sm');
                        document.documentElement.setAttribute("data-sidebar-size", "sm");
                    } else {
                        document.documentElement.setAttribute("data-sidebar-size", "lg");
                        store.commit('layout/CHANGE_SIDEBAR_TYPE', 'lg');
                    }
                } else if (windowSize <= 767) {
                    document.body.classList.add("vertical-sidebar-enable");
                    document.documentElement.setAttribute("data-sidebar-size", "lg");
                }
            }

            if (windowSize > 767 && store.state.layout.sidebarSize === "sm") {
                document.querySelector(".hamburger-icon").classList.toggle("open");
            } else {
                document.querySelector(".hamburger-icon").classList.remove("open");
            }
        },
        toggleMenu() {
            this.$parent.toggleMenu();
        },
        toggleDarkMode() {
            if (document.documentElement.getAttribute("data-layout-mode") == "dark") {
                // Remover modo escuro
                document.documentElement.setAttribute("data-layout-mode", "light");
                document.documentElement.setAttribute("data-sidebar", "light");
                localStorage.setItem('mode', 'light');
                document.body.style.backgroundColor = '';
            } else {
                // Ativar modo escuro
                document.documentElement.setAttribute("data-layout-mode", "dark");
                document.documentElement.setAttribute("data-sidebar", "dark");
                localStorage.setItem('mode', 'dark');
                document.body.style.backgroundColor = '#000';
            }
        }

    },
    mounted() {
        var windowSize = document.documentElement.clientWidth;
        if (windowSize > 767 && store.state.layout.sidebarSize === "sm") {
            if (document.querySelector(".hamburger-icon")) document.querySelector(".hamburger-icon").classList.toggle("open");
        } else {
            if (document.querySelector(".hamburger-icon")) document.querySelector(".hamburger-icon").classList.remove("open");
        }

        document.addEventListener("scroll", function () {
            var pageTopbar = document.getElementById("page-topbar");
            if (pageTopbar) {
                document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50 ? pageTopbar.classList.add(
                    "topbar-shadow") : pageTopbar.classList.remove("topbar-shadow");
            }
        });
        if (document.getElementById("topnav-hamburger-icon"))
            document
                .getElementById("topnav-hamburger-icon")
                .addEventListener("click", this.toggleHamburgerMenu);

    },
    computed: {
        ...authComputed,
        user: {
            get() {
                if (this.$store.state.auth.currentUser == null || Object.keys(this.$store.state.auth.currentUser).length === 0) this.refreshUser();
                return this.$store ? this.$store.state.auth.currentUser : {} || {};
            },
        },
    }
};
</script>
<!--eslint-disable no-mixed-spaces-and-tabs-->
<template>
    <header id="page-topbar">
        <div class="layout-width">
            <div class="navbar-header">
                <div class="d-flex">
                    <!-- LOGO -->
                    <div class="navbar-brand-box horizontal-logo">
                        <router-link to="/" class="logo logo">
                            <span class="logo-sm">
                                <img src="../../assets/images/logo-sm.png" alt="" height="22" />
                            </span>
                        </router-link>

                        <router-link to="/" class="logo logo-light">
                            <span class="logo-sm">
                                <img src="../../assets/images/logo-sm.png" alt="" height="22" />
                            </span>
                        </router-link>
                    </div>

                    <button type="button" class="
                          btn btn-sm
                          px-3
                          fs-16
                          header-item
                          vertical-menu-btn
                          topnav-hamburger
                        " id="topnav-hamburger-icon">
                        <span class="hamburger-icon">
                            <span></span>
                            <span></span>
                            <span></span>
                        </span>
                    </button>


                </div>

                <div class="d-flex align-items-center">
                    <div class="ms-1 header-item d-none d-sm-flex">
                        <b-button type="button" variant="ghost-secondary"
                            class="btn-icon btn-topbar rounded-circle light-dark-mode" @click="toggleDarkMode">
                            <i class="bx bx-moon fs-22"></i>
                        </b-button>
                    </div>

                    <div class="dropdown ms-sm-3 header-item topbar-user">
                        <button type="button" class="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            <span class="d-flex align-items-center">
                                <img class="rounded-circle header-profile-user" :src="user.photo_url"
                                    alt="Header Avatar" />
                                <span class="text-start ms-xl-2">
                                    <span class=" d-none d-xl-inline-block ms-1 fw-medium user-name-text">{{
                                user.name
                            }}</span>
                                    <span class="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">{{
                                    user.office
                                }}</span>
                                </span>
                            </span>
                        </button>
                        <div class="dropdown-menu dropdown-menu-end">
                            <h6 class="dropdown-header">Bem vindo {{ user.name }}!</h6>
                            <router-link class="dropdown-item" to="/meu-perfil"><i
                                    class="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                                <span class="align-middle">Meu perfil</span>
                            </router-link>
                            <b-link class="dropdown-item" href="/logout"><i
                                    class="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
                                <span class="align-middle" data-key="t-logout">Sair</span>
                            </b-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>
<style>
.dark-mode {
    background-color: #000;
    color: #fff;
}
</style>
